<template>
  <div class="container">
    <edit-header
      entity="customerProfile"
      :error.sync="error"
      :invalid.sync="invalid"
      :submitted.sync="submitted"
      :isNew="isNew"></edit-header>
    <form
      v-disable-all="!can(uiPermissions.CUSTOMER_PROFILES_UPDATE)"
      ref="form"
      class="validation"
      novalidate
      @submit.prevent="submit">
      <div class="row">
        <div class="col">
          <div data-test="card-customer-profile" class="card">
            <div class="card-header">
              <h3 class="card-title">Customer profile</h3>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label class="form-label">Name <span class="text-danger">*</span></label>
                <input
                  v-model="item.name"
                  type="text"
                  data-test="txt-name"
                  class="form-control"
                  :class="{ 'is-invalid': invalid && response.error.name }"
                  required>
                <small v-if="invalid && response.error.name" class="error text-danger">
                  {{response.error.name.join(',')}}
                </small>
              </div>
              <div class="form-group">
                <label class="form-label">Description</label>
                <textarea
                  v-model="item.description"
                  data-test="txt-description"
                  class="form-control"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <edit-footer
            :dirty="dirty"
            entity="customerProfile"
            :isNew="isNew"
            :submitting="submitting"
            :hide-delete="true"></edit-footer>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import edit from '@/mixins/edit';
import {customerProfiles} from '@/services';

export default {
  name: 'CustomerProfileEdit',
  components: {
    EditFooter,
    EditHeader,
  },
  mixins: [
    edit,
  ],
  data() {
    return {
      item: {
        description: '',
        name: '',
      },
    };
  },
  computed: {
    route() {
      return `/customer-profiles/${this.item.id}`;
    },
  },
  methods: {
    fetchData(id) {
      return customerProfiles.getById(id);
    },
    submitData(item) {
      return customerProfiles.saveOrUpdate(item);
    },
  },
};

</script>
